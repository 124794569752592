









import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    value: { type: String },
  },
})
export default class RootNav extends Vue {}
