































import { Component, Vue } from 'vue-property-decorator';
import { ref } from '@vue/composition-api';
import { latestVersion } from '../lib/versions';
import CrossedSwordsIcon from '../icons/crossed-swords.vue';
import BlacksmithIcon from '../icons/blacksmith.vue';
import FlaskIcon from '../icons/flask.vue';
import ScrollBookIcon from '../icons/scroll-book.vue';
import TargetDummyIcon from '../icons/target-dummy.vue';
import Tooltip from './Tooltip.vue';

@Component({
  components: {
    BlacksmithIcon,
    CrossedSwordsIcon,
    FlaskIcon,
    ScrollBookIcon,
    TargetDummyIcon,
    Tooltip,
  },
  props: {
    value: { type: String },
  },
  setup() {
    const hasNewVersions = ref(false);

    // check if a new version is available, animate the versions icon
    const lastSeenVersion = window.localStorage.getItem('battletris-version');
    if (!lastSeenVersion || lastSeenVersion !== latestVersion) {
      hasNewVersions.value = true;
    }

    return {
      hasNewVersions,
    };
  },
})
export default class QuickLinks extends Vue {}
